@use 'variables' as v;

// generic stuff

.main {
  padding-left: 1em;
  padding-right: 1em;
  margin: 65px 22% 1rem calc(22% - 200px); // 55 px for the navbar and an extra 10 as a spacer
}

@media screen and (max-width: v.$breakpoint-lg) {
  .main {
    margin: 65px 0 1rem 0;
  }
}

.spacer {
  margin-bottom: 1em;
}

.bottom {
  align-content: flex-end;
}

.flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.hide-bullets {
  list-style-type: none;
  padding: 0;
  margin-bottom: 0;
}

.table-of-contents {
  border: 2px solid lightgray;
  background-color: white;
  background-image: v.$bg-card;
  padding: 0.8em;
  border-radius: 10px;
  width: max-content;
  max-width: 100%;
  margin: 0 0.5em 0.5em 0.5em;

  ol {
    margin-bottom: 0;
  }
}

// as in opens a new tab
.new-tab {
  font-size: calc(0.6rem + 0.1vw);
  text-decoration: none;
  padding-left: 0.2rem;
}

// Game Data Pages

.card-item-display {
  margin: 0 auto 0 auto;
}

// Trait specific
.trait-color {
  color: lightgray;
}

.trait-colortrue {
  color: black;
}

.svg-color {
  opacity: 35%;
}

// universal styles for blog pages
.center {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.right {
  float: right;
  margin-left: 1em;
  text-align: left;
}

.left {
  float: left;
  margin-right: 1em;
}

.spread-apart {
  justify-content: space-around;
}

// https://css-loaders.com/spinner/
.loader {
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: v.$primary;
  --_m:
    conic-gradient(#0000 10%, #000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

@keyframes l3 {
  to {
    transform: rotate(1turn)
  }
}

// often used for fake buttons
.btn {
  color: white;
  padding: .375rem .75rem;
  font-size: 1.1rem;
  line-height: 1.5;
  font-weight: 400;
  border-radius: .375rem;
  border: none;
}

.btn:hover {
  filter: brightness(115%);
}

@each $w in 25 33 40 50 66 75 {
  .w-#{$w} {
    width: #{$w + '%'};
  }
}

@media screen and (max-width: v.$breakpoint-md) {

  @each $w in 25 33 {
    .w-#{$w} {
      width: 50%;
    }
  }

  @each $w in 40 50 66 75 {
    .w-#{$w} {
      width: 100%;
    }
  }
}

.bg-primary {
  background-color: v.$primary;
}

.bg-secondary {
  background-color: v.$secondary;
}

.bg-danger {
  background-color: v.$danger;
}

.bg-success {
  background-color: v.$success;
}

.text-danger {
  color: v.$danger;
}

.text-success {
  color: v.$success;
}

.text-muted {
  color: rgba(33, 37, 41, .75);
}

.svg-filter {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}